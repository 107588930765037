import styled from "styled-components"

export const LoadingContainer = styled.div`
background: #33682c;
display: flex;
justify-content: center;
align-items: center;
padding: 0 30px;
min-height: 100vh;
z-index: 1;
`;

export const LoadingWrapper = styled.div`
top: 0;
right: 0;
bottom: 0;
left: 0;
height: 100%;
width: 100%;
overflow: hidden;
text-align: center;
justify-content: column;
align-items: center;
`;

export const LoadingText = styled.h1`
font-size: 64px;
color: #fff;
letter-spacing: 5px;

@media screen and (max-width: 768px){
    font-size: 48px;
}

@media screen and (max-width: 480px){
    font-size: 36px;
}
`;