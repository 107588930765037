import React from 'react'
import { 
    LoadingContainer,
    LoadingWrapper,
    LoadingText
} from './LoadingElements2'
import './styles/spinner.css'

const LoadingScreen2 = () => {
    return (
        <LoadingContainer>
            <LoadingWrapper>
                <LoadingText>Loading</LoadingText>
                    <div className='spinner'>
                    <div className='bounce1'></div>
                    <div className='bounce2'></div>
                    <div className='bounce3'></div>
                    </div>
            </LoadingWrapper>
        </LoadingContainer>
    )
}

export default LoadingScreen2
